<script setup lang="ts">
import type { Service } from '~/models/Service'
import type { Specialization } from '~/models/Specialization'
import {
  UiIconsCheckBadge,
  UiIconsDate,
  UiIconsMessage,
  UiIconsFolder,
  UiIconsLightBulb,
  UiIconsSearch,
  UiIconsEarth,
  UiIconsThumbUp,
} from '#components'

const props = defineProps({
  title: {
    type: String,
    required: true,
    default: '',
  },
  subtitle: {
    type: String,
    default: '',
  },
  picture: {
    type: String,
    required: true,
    default: '',
  },
  specializations: {
    type: Array<Specialization>,
    required: true,
  },
  services: {
    type: Array<Service>,
  },
  displayUniqueService: {
    type: Boolean,
    default: true,
  },
})

let firstSpecialization: Specialization
let otherSpecializations: Specialization[]

if (props.specializations.length) {
  firstSpecialization = props.specializations[0]
  otherSpecializations = props.specializations.slice(1)
}

const icons: { [key: string]: any } = {
  check: UiIconsCheckBadge,
  search: UiIconsSearch,
  folder: UiIconsFolder,
  date: UiIconsDate,
  message: UiIconsMessage,
  idea: UiIconsLightBulb,
  earth: UiIconsEarth,
  thumbUp: UiIconsThumbUp,
}
</script>

<template>
  <div class="tailor-made-container">
    <div class="container">
      <UiElementsWaveTitle>
        <h2>
          {{ title }}
        </h2>
      </UiElementsWaveTitle>
      <p class="subtitle">
        {{ subtitle }}
      </p>
      <div class="content">
        <div class="content--top">
          <div
            v-if="firstSpecialization"
            class="content--top-left"
          >
            <div class="content-title">
              <component
                :is="icons[firstSpecialization.icon]"
                v-if="icons[firstSpecialization.icon]"
              />
              <span class="big">
                {{ firstSpecialization.subtitle }}
              </span>
            </div>
            <div class="like-h2">
              {{ firstSpecialization.title }}
            </div>
            <p>
              {{ firstSpecialization.description }}
            </p>
          </div>
          <div
            v-if="picture"
            class="content--top-middle"
          >
            <NuxtImg
              loading="lazy"
              format="webp"
              :src="getStrapiMedia(picture)"
              :alt="'Tailor Made picture'"
            />
          </div>
          <div
            v-if="otherSpecializations"
            class="content--top-right"
          >
            <template
              v-for="otherSpecialization in otherSpecializations"
              :key="otherSpecialization.id"
            >
              <div>
                <div
                  class="content-title"
                >
                  <component
                    :is="icons[otherSpecialization.icon]"
                    v-if="icons[otherSpecialization.icon]"
                  />
                  <span>
                    {{ otherSpecialization.subtitle }}
                  </span>
                </div>
                <div class="like-h2">
                  {{ otherSpecialization.title }}
                </div>
                <p
                  v-if="otherSpecialization.description"
                >
                  {{ otherSpecialization.description }}
                </p>
              </div>
            </template>
          </div>
        </div>
        <div
          v-if="displayUniqueService && services"
          class="content--bottom"
        >
          <UiElementsWaveTitle>
            <div class="like-h4 title--italic">
              {{ $t('about.tailorMade.uniqueService.title') }}
            </div>
          </UiElementsWaveTitle>
          <div class="unique-service-list">
            <div
              v-for="service in services"
              :key="service.id"
              class="unique-service-item"
            >
              <div class="title-container">
                <component
                  :is="icons[service.icon]"
                  v-if="icons[service.icon]"
                />
                <span>
                  {{ service.title }}
                </span>
              </div>
              <p>
                {{ service.description }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.tailor-made-container {
  background-color: var(--color-navy-0);
  padding: $space-lg 0;

  &.homepage {
    background: none;
  }

  .like-h2 {
    font-size: 1.5rem;

    @media (min-width: map-get($grid-breakpoints, lg)) {
      font-size: 2.5rem;
    }
  }

  .subtitle {
    text-align: center;
    padding-top: $space-sm;
    font-family: var(--font-secondary);
    font-style: italic;

    @media (min-width: map-get($grid-breakpoints, lg)) {
        font-size: 1.5rem;
    }
  }

  .content--top {
    display: flex;
    flex-direction: column;

    @media (min-width: map-get($grid-breakpoints, lg)) {
      flex-direction: row;
      justify-content: space-between;

      .content--top-middle {
        width: 25%
      }

      > div {
        width: 30%;
      }
    }

    .content--top-middle {
      order: -1;
      padding-top: $space-lg;

      @media (min-width: map-get($grid-breakpoints, lg)) {
        order: initial;
      }

      img {
        display: block;
        width: 100%;
      }
    }
  }

  .content--bottom {
    .wave-title {
      h2 {
        @media (min-width: map-get($grid-breakpoints, lg)) {
          padding-top: $space-2xl;
        }
      }
    }
  }

  .content {
    .content-title {
      display: flex;
      align-items: center;
      gap: .5em;
      padding: 1.5em 1em 0 1em;

      @media (min-width: map-get($grid-breakpoints, md)) {
        svg {
          font-size: 1.6em;
        }
      }

      span {
        font-family: var(--font-secondary);
        font-style: italic;
        font-size: 1.2em;
        font-weight: 600;

        @media (min-width: map-get($grid-breakpoints, lg)) {
          &.big {
            font-size: 1.5em;
          }
        }
      }
    }

    .like-h2 {
      text-align: start;
      font-family: var(--font-secondary);
      text-transform: none;
      font-size: 1.4rem;
      margin: 0;
      padding: $space-sm 1rem;
      font-weight: 500;

      @media (min-width: map-get($grid-breakpoints, lg)) {
        font-size: 1.375rem;

        &.big {
          font-size: 2.5rem;
        }
      }
    }

    .like-h4 {
      text-transform: none;
    }

    p {
      margin: 0;
      line-height: 1.5rem;
      padding: 0 1rem;
    }
  }

  .unique-service-list {
    display: flex;
    flex-direction: column;
    gap: $space-lg;
    padding: $space-lg 1rem;

    @media (min-width: map-get($grid-breakpoints, lg)) {
      flex-direction: row;
      gap: $space-2xl;
    }

    .unique-service-item {
      .title-container {
        display: flex;
        align-items: center;
        gap: 1rem;
        font-size: 1.5rem;
        padding-bottom: $space-sm;

        span {
          font-size: 1.3rem;
          font-family: var(--font-secondary);
        }
      }

      p {
        padding: 0;
      }
    }
  }
}
</style>
